<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="baseData">
          {{ $t('erp.lang_nav_waregroups') }}
        </v-tab>

        <v-tab v-if="this.isTranslationEnabled" key="translation">
          {{ $t('erp.lang_translation') }}
        </v-tab>

        <v-tabs-items touchless v-model="tab">
          <br>
          <v-tab-item key="baseData">
            <v-layout>
              <!-- Table: Click -->
              <v-flex md5 mr-2 ml-4>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="itemGroupName"
                              :label="$t('erp.lang_itemgroup_name')"
                              autocomplete="off"
                              required
                ></v-text-field>
              </v-flex>
              <v-flex md2 mr-2 ml-2>
              </v-flex>
              <v-flex md5 mr-4 ml-2>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="itemPriceCalcSurplus"
                              :label="$t('erp.lang_surplusToBuyPrice')"
                              autocomplete="off"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <!-- Table: Click -->
              <v-flex md5 mr-2 ml-4>
                <v-select outlined
                          v-if="this.$store.getters['permissions/checkModule'](2)"
                          :items="itemGroupOrderbonIDSelector"
                          item-text="name"
                          item-value="id"
                          v-model="itemGroupStdOrderPrinterID"
                          :label="$t('settings.langOrderbonPrinter')"
                ></v-select>
                <v-select outlined
                          v-if="this.$store.getters['permissions/checkModule'](2)"
                          :items="itemGroupOrderbonIDSelector"
                          item-text="name"
                          item-value="id"
                          v-model="dupItemGroupStdOrderPrinterID"
                          :label="$t('settings.lang_duplicateToOrderbonPrinter')"
                ></v-select>
              </v-flex>
              <v-flex md2 mr-2 ml-2>
              </v-flex>
              <v-flex md5 mr-4 ml-2>
                <v-select outlined v-if="this.$store.getters['permissions/checkModule'](30)"
                          :items="itemGroupCourseIDSelector"
                          item-text="name"
                          item-value="id"
                          :clearable=true
                          v-model="itemGroupStdCourseID"
                          :label="$t('erp.lang_stadCourseIDWG')"
                ></v-select>
                <v-select outlined v-if="this.$store.getters['permissions/checkModule'](30)"
                          :items="kitchenMonitors"
                          item-text="1"
                          item-value="0" multiple chips
                          v-model="selectedKitchenMonitors"
                          :label="$t('erp.lang_displayKitchenMonitor')"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout>
              <!-- Table: Click -->
              <v-flex md5 mr-2 ml-4>
                <strong>{{ $t('erp.lang_colourofWaregroup') }}:</strong>
                <v-color-picker
									v-model="itemGroupColorPicker"
									mode="hexa"
									show-swatches
									hide-mode-switch
                  hide-canvas
									elevation="1"
									width="100%"
									class="color-swatcher-without-transparency mx-auto my-8"
								></v-color-picker>

                <br>

                <fontawesome-picker :fieldLabel="$t('erp.lang_chooseWaregroupIcon')"
                                    v-model="select"></fontawesome-picker>

                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="pmsItemizer"
                              :label="$t('erp.lang_pms_itemizer')"
                              autocomplete="off"
                              required
                ></v-text-field>


                <v-textarea v-model="itemGroupDescription" @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')" autocomplete="off" outlined
                            dense/>
              </v-flex>
              <v-flex md2 mr-2 ml-2>
              </v-flex>
              <v-flex md5 mr-4 ml-2>

                <v-switch
                    v-model="itemGroupColorSwitch"
                    :label="$t('erp.lang_colourWares')"
                ></v-switch>

                <v-switch
                    v-model="itemGroupDisplaySwitch"
                    :label="$t('erp.lang_displayWaregroup')"
                ></v-switch>

                <v-switch v-if="this.$store.getters['permissions/checkModule'](10)"
                          v-model="itemGroupDisplayInKitchenMonitor"
                          :label="$t('erp.lang_displayKitchenMonitor')"
                ></v-switch>


                <strong>{{ $t('erp.mealType') }}</strong><br>

                <v-radio-group row v-model="itemGroupTypeChooser">
                  <v-radio v-if="this.$store.getters['permissions/checkModule'](2)"
                           :key="0"
                           :value="1"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'cocktail']" size="3x"/>
                        <p>{{ $t('generic.lang_beverage') }}</p></div>
                    </template>
                  </v-radio>

                  <v-radio v-if="this.$store.getters['permissions/checkModule'](2)"
                           :key="1"
                           :value="2"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'meat']" size="3x"/>
                        <p>{{ $t('generic.lang_food') }}</p></div>
                    </template>
                  </v-radio>

                  <v-radio
                      :key="2"
                      :label="`Radio`"
                      :value="3"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'gifts']" size="3x"/>
                        <p>{{ $t('generic.lang_item') }}</p></div>
                    </template>
                  </v-radio>
                  <v-radio
                      :key="3"
                      :label="`Radio`"
                      :value="4"
                  >
                    <template slot="label">
                      <div>
                        <font-awesome-icon :icon="['fal', 'concierge-bell']" size="3x"/>
                        <p>{{ $t('generic.lang_service') }}</p></div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-radio-group v-model="ageVerification">
                  <v-radio
                      key="1"
                      :label="$t('erp.lang_ageVerification') + ' ' + $t('settings.lang_settings_deactiviert')"
                      value="0"
                  ></v-radio>
                  <v-radio
                      key="2"
                      :label="$t('erp.lang_ageVerification') + ' 16 ' + $t('generic.lang_yearsOfAge')"
                      value="16"
                  ></v-radio>
                  <v-radio
                      key="3"
                      :label="$t('erp.lang_ageVerification') + ' 18 ' + $t('generic.lang_yearsOfAge')"
                      value="18"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-tab-item>

          <!--attributes translation-->
          <v-tab-item v-if="this.isTranslationEnabled" key="translation">
            <b-tabs>

              <!-- germany -->
              <b-tab active>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="DE"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_de"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-textarea v-model="itemGroupDescription_de" @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                autocomplete="off" outlined
                                dense/>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- english -->
              <b-tab>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="GB"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_en"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-textarea v-model="itemGroupDescription_en" @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                autocomplete="off" outlined
                                dense/>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- germany -->
              <b-tab>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="FR"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_fr"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-textarea v-model="itemGroupDescription_fr" @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                autocomplete="off" outlined
                                dense/>
                  </v-col>
                </v-row>
              </b-tab>

              <!-- arabic -->
              <b-tab>
                <template #title>
                  <v-btn text class="ma-0">
                    <country-flag country="SA"/>
                  </v-btn>
                </template>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-text-field @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="itemGroupName_ar"
                                  :label="$t('erp.lang_itemgroup_name')"
                                  autocomplete="off" outlined dense></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-textarea v-model="itemGroupDescription_ar" @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('generic.lang_description')"
                                autocomplete="off" outlined
                                dense/>
                  </v-col>
                </v-row>
              </b-tab>
            </b-tabs>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-container fluid :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
        <v-layout>
          <v-flex class="text-right">
            <v-btn text color="error" @click="goBacktoItemgroups">{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn color="success" :disabled="itemGroupName.length < 1 || this.creating" :loading="this.creating"
                   @click="addData">{{
                $t('generic.lang_add')
              }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import {library} from '@fortawesome/fontawesome-svg-core';
import CountryFlag from "vue-country-flag";

import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faConciergeBell,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faHotdog,
  faMeat,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco
} from '@fortawesome/pro-light-svg-icons'


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import mixin from "../../../mixins/KeyboardMixIns";
import FontawesomePicker from "../../common/iconPicker";
import {createNamespacedHelpers} from "vuex";
import Template from "@/components/settings/customerdisplay/Template";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup
);
export default {
  components: {
    Template,
    'font-awesome-icon': FontAwesomeIcon,
    FontawesomePicker,
    CountryFlag
  },
  mixins: [mixin],
  data() {
    return {
      creating: false,
      ENDPOINTS,
      select: '',
      id: null,
      itemGroupName: "",
      itemGroupName_de: "",
      itemGroupName_en: "",
      itemGroupName_fr: "",
      itemGroupName_ar: "",
      itemGroupDescription: "",
      itemGroupDescription_de: "",
      itemGroupDescription_en: "",
      itemGroupDescription_fr: "",
      itemGroupDescription_ar: "",
      itemPriceCalcSurplus: "",
      itemGroupStdOrderPrinterID: "",
      dupItemGroupStdOrderPrinterID: "",
      itemGroupStdCourseID: "",
      itemgroupAdd: "",
      itemGroupTypeChooser: 3,
      itemGroupDisplayInKitchenMonitor: true,
      itemGroupDisplaySwitch: true,
      itemGroupColorSwitch: true,
      itemGroupColorPicker: "",
      itemGroupOrderbonIDSelector: [],
      itemGroupCourseIDSelector: [],
      loading: false,
      ageVerification: "0",
      pmsItemizer: "",
      tab: 0,
      selectedKitchenMonitors: null,
      kitchenMonitors: [],
      loadingKitchenMonitors: false,
    }
  },

  watch: {},

  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    },
  },
  methods: {
    goBacktoItemgroups: function () {
      this.$router.push('/erp/baseData/itemgroups');
    },
    addData: function () {
      let self = this;
      this.creating = true;
      this.axios.post(ENDPOINTS.ERP.ITEMGROUP.CREATE, {

        wareGroupName: this.itemGroupName,
        wareGroupName_de: this.itemGroupName_de,
        wareGroupName_en: this.itemGroupName_en,
        wareGroupName_fr: this.itemGroupName_fr,
        wareGroupName_ar: this.itemGroupName_ar,
        description: this.itemGroupDescription,
        itemGroupDisplayInKitchenMonitor: (this.itemGroupDisplayInKitchenMonitor) ? 1 : 0,
        wareGroupEKAufschlag: this.itemPriceCalcSurplus,
        orderBonPrinterID: this.itemGroupStdOrderPrinterID,
        duplicate_orderbonPrinterID: this.dupItemGroupStdOrderPrinterID,
        createWaregroupColor: this.itemGroupColorPicker,
        addWaregroupColourWares: this.itemGroupColorSwitch,
        addWaregroupDisplay: this.itemGroupDisplaySwitch,
        createWaregroupGastroFoodTypeChooser: this.itemGroupTypeChooser,
        waregroupIcon: this.select,
        stdCourseWaregroupID: this.itemGroupStdCourseID,
        ageVerification: this.ageVerification,
        wareGroupPMSItemizer: this.pmsItemizer,
        itemgroupKitchenMonitors: this.selectedKitchenMonitors,
        description_de: this.itemGroupDescription_de,
        description_en: this.itemGroupDescription_en,
        description_fr: this.itemGroupDescription_fr,
        description_ar: this.itemGroupDescription_ar,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_itemgroupCreated'),
            color: "success"
          });
          this.goBacktoItemgroups();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => this.creating = true);
    },
    getData: function () {

      this.axios.post(ENDPOINTS.ERP.ITEMGROUP.GET, {}).then((res) => {
        if (res.status === 200) {

          res.data.orderbonPrinter.forEach((row) => {
            this.itemGroupOrderbonIDSelector.push({name: row['1'], id: row['0']});
          });

          res.data.courseIDs.forEach((row) => {
            this.itemGroupCourseIDSelector.push({name: row[1], id: parseInt(row[0])});
          });

        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },


    resetData: function () {
      this.id = null;
      this.text = "";
    },
    getKitchenMonitors() {
      this.loadingKitchenMonitors = true;
      this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.GETALL).then(res => {
        if (res.data.SUCCESS) {
          this.kitchenMonitors = res.data['0'];
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_errorWhileGettingKitchenMonitors'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_fehler') + ' : ' + err.message,
          color: "success"
        });
      }).finally(() => {
        this.loadingKitchenMonitors = false;
      })
    }

  },
  mounted() {
    this.getKitchenMonitors()
    this.getData();
  },
}
</script>

<style>
.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__alpha {
  visibility: hidden;
}

.color-swatcher-without-transparency
  .v-color-picker__sliders
  .v-color-picker__hue {
  margin-bottom: 0 !important;
}

.color-swatcher-without-transparency
  .v-color-picker__swatch:nth-child(20)
  > .v-color-picker__color:nth-child(3) {
  visibility: hidden;
}
</style>
